import React from 'react';
import { Container, Header, Box } from '@cloudscape-design/components';
import './YouTubeVideo.css';

const YouTubeVideo = () => {
    return (
        <Box className="youtube-video-frame">
            <Container header={<Header variant="h2">Live Weather Stream</Header>} className="youtube-video">
                <iframe
                    width="100%"
                    height="315"
                    src="https://player.twitch.tv/?channel=bannercreeksciencecenter&parent=dev.holtonksweather.com"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </Container>
        </Box>
    );
};

export default YouTubeVideo;
