import React, { useEffect, useState } from 'react';
import { fetchCurrentConditions } from '../api/weatherApi';
import { Container, Header, Box, Button, SpaceBetween, Select } from '@cloudscape-design/components';
import SunCalc from 'suncalc';
import './CurrentConditions.css';

const CurrentConditions = ({ site, city }) => {
    const [data, setData] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showGraphs, setShowGraphs] = useState(false);
    const [selectedTimeframe, setSelectedTimeframe] = useState('day');

    useEffect(() => {
        const getData = async () => {
            const result = await fetchCurrentConditions(site);
            setData(result);
        };
        getData();
    }, [site]);

    const convertTimeTo12Hour = (time) => {
        if (!time) return ''; // Return empty string if time is undefined
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${ampm}`;
    };

    const calculateSunTimes = () => {
        if (data) {
            const times = SunCalc.getTimes(new Date(), 39.465998439437215, -95.74040734602868);
            const sunrise = convertTimeTo12Hour(times.sunrise.toTimeString().slice(0, 5));
            const sunset = convertTimeTo12Hour(times.sunset.toTimeString().slice(0, 5));
            return { sunrise, sunset };
        }
        return { sunrise: '', sunset: '' };
    };

    const sunTimes = calculateSunTimes();

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };



    const handleTimeframeChange = (event) => {
        setSelectedTimeframe(event.detail.selectedOption.value);
    };

    
    if (!data) return <div>Loading...</div>;

    return (
        <Box className="current-conditions-frame">
            <Container header={<Header variant="h2">Current Conditions</Header>} className="current-conditions">
                <Box className="current-conditions-content">
                    <Header variant="h3">
                        Current Conditions at {convertTimeTo12Hour(data.time)} in {city} on {data.date}
                    </Header>
                    <Box className="current-conditions-icon">
                        <img src={data.icon} alt="Weather icon" />
                    </Box>
                    <Box className="current-conditions-details">
                        <p><strong>{data.forecast}</strong></p>
                        <p><strong>Temperature:</strong> {data.temp}°F</p>
                        <p><strong>Feels Like:</strong> {data.feels}°F</p>
                        <p><strong>Dewpoint:</strong> {data.dewpoint}°F</p>
                        <p><strong>Humidity:</strong> {data.humidity}%</p>
                        <p><strong>Pressure:</strong> {data.pressure} in and {data.trend}</p>
                        <p><strong>Rain Today:</strong> {data.rain} in</p>
                        <p><strong>Wind:</strong> {data.wind} mph {data.windDir} with gusts to {data.gust} mph</p>
                    </Box>
                    <Button onClick={handleToggleExpand}>
                        {isExpanded ? 'Hide Detailed Conditions' : 'View Detailed Conditions'}
                    </Button>
                    {isExpanded && (
                        <Box className="detailed-conditions">
                            <p><strong>Sunrise:</strong> {sunTimes.sunrise}</p>
                            <p><strong>Sunset:</strong> {sunTimes.sunset}</p>
                            <p><strong>Solar Radiation:</strong> {data.srad} W/m²</p>
                            <p><strong>UV Index:</strong> {data.uv}</p>
                        </Box>
                    )}
                </Box>

            </Container>
        </Box>
    );
};

export default CurrentConditions;