import React, { useEffect, useState } from 'react';
import { fetchForecast } from '../api/weatherApi';
import { Box, Container, Header, SpaceBetween } from '@cloudscape-design/components';
import './Forecast.css';

const Forecast = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const result = await fetchForecast();
            setData(result);
        };
        getData();
    }, []);

    if (!data.length) return <div>Loading...</div>;

    return (
        <Box className="forecast-frame">
            <Container header={<Header variant="h2" textAlign="center">Forecast</Header>} className="forecast-container">
                <Box className="forecast-cards">
                    {data.map((period, index) => (
                        <Box key={index} className="forecast-card">
                            <Header variant="h4">{period.day}</Header>
                            <img src={period.icon} alt={period.description} />
                            <p><strong>{period.description}</strong></p>
                            <p>Temperature: {period.temp}°F</p>
                            <p>Wind: {period.wind} mph {period.windDir}</p>
                            <p>Rain: {period.rain}%</p>
                        </Box>
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default Forecast;
