export const fetchCurrentConditions = async (site) => {
    const response = await fetch(`https://rnw8t0mt1m.execute-api.us-east-2.amazonaws.com/dev/current?site=${site}`, {
        headers: {
            'x-api-key': '0oDTS4b0A169iPzoUzjd43W6ikVzbX0p6Difg7ot'
        }
    });
    const data = await response.json();
    return data;
};

export const fetchForecast = async () => {
    const response = await fetch('https://rnw8t0mt1m.execute-api.us-east-2.amazonaws.com/dev/forecast/nws', {
        headers: {
            'x-api-key': '0oDTS4b0A169iPzoUzjd43W6ikVzbX0p6Difg7ot'
        }
    });
    const data = await response.json();
    return data;
};
