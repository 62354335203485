import React, { useState, useEffect } from 'react';
import { Box, Container, Header, Select, Button } from '@cloudscape-design/components';
import './Graphs.css';

const Graphs = ({ city }) => {
    const [selectedTimeframe, setSelectedTimeframe] = useState('day');
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState('');

    const handleTimeframeChange = (event) => {
        setSelectedTimeframe(event.detail.selectedOption.value);
    };

    useEffect(() => {
        setSelectedTimeframe('day');  // Reset to 'day' whenever the city changes
    }, [city]);

    const graphSrc = (type) => {
        const baseUrl =
            city === 'KDEN'
                ? 'https://s3-us-east-2.amazonaws.com/holtonksweather/KDEN'
                : 'https://s3-us-east-2.amazonaws.com/holtonksweather/WeeWX';
        return `${baseUrl}/${selectedTimeframe}${type}.png`;
    };

    const handleImageClick = (src) => {
        setLightboxImage(src);
        setIsLightboxOpen(true);
    };

    return (
        <Box className="graphs-frame">
            <Container header={<Header variant="h2">Weather Graphs</Header>}>
                <Select
                    selectedOption={{ value: selectedTimeframe, label: selectedTimeframe }}
                    onChange={handleTimeframeChange}
                    options={[
                        { value: 'day', label: 'Day' },
                        { value: 'week', label: 'Week' },
                        { value: 'month', label: 'Month' },
                        { value: 'year', label: 'Year' }
                    ]}
                />
                <Box className="graphs-container">
                                <img src={graphSrc('tempdew')} alt={`${selectedTimeframe} Temperature Graph`} onClick={() => handleImageClick(graphSrc('tempdew'))} />
                                <img src={graphSrc('rain')} alt={`${selectedTimeframe} Rain Graph`} onClick={() => handleImageClick(graphSrc('rain'))} />
                                <img src={graphSrc('wind')} alt={`${selectedTimeframe} Wind Graph`} onClick={() => handleImageClick(graphSrc('wind'))} />
                                <img src={graphSrc('tempfeel')} alt={`${selectedTimeframe} Temperature Graph`} onClick={() => handleImageClick(graphSrc('tempfeel'))} />
                                <img src={graphSrc('hum')} alt={`${selectedTimeframe} Rain Graph`} onClick={() => handleImageClick(graphSrc('hum'))} />
                                <img src={graphSrc('winddir')} alt={`${selectedTimeframe} Wind Graph`} onClick={() => handleImageClick(graphSrc('winddir'))} />
                                <img src={graphSrc('barometer')} alt={`${selectedTimeframe} Temperature Graph`} onClick={() => handleImageClick(graphSrc('barometer'))} />
                                <img src={graphSrc('radiation')} alt={`${selectedTimeframe} Rain Graph`} onClick={() => handleImageClick(graphSrc('radiation'))} />
                                <img src={graphSrc('uv')} alt={`${selectedTimeframe} Wind Graph`} onClick={() => handleImageClick(graphSrc('uv'))}  />
                </Box>
            </Container>
            {isLightboxOpen && (
                <div className="lightbox" onClick={() => setIsLightboxOpen(false)}>
                    <img src={lightboxImage} alt="Full-size graph" />
                </div>
            )}
        </Box>
    );
};

export default Graphs;
