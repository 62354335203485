import React, { useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import { fetchCurrentConditions } from '../api/weatherApi';
import { Container, Header, Box } from '@cloudscape-design/components';
import './WeatherMap.css';
import 'maplibre-gl/dist/maplibre-gl.css';

const WeatherMap = () => {
    const [map, setMap] = useState(null);

    const locations = [
        { name: 'Holton', site: 'KHLT', coordinates: [-95.79495448242118, 39.450517158525074] },
        { name: 'Denison', site: 'KDEN', coordinates: [-95.62728030971222, 39.3902471801159] }
    ];

    useEffect(() => {
        const map = new maplibregl.Map({
            container: 'map',
            style: 'https://maps.geo.us-east-2.amazonaws.com/maps/v0/maps/HKSMap2/style-descriptor?key=v1.public.eyJqdGkiOiIyY2YwMTNlYy03NmQ0LTRlNmUtYTc2NC02Y2FlNGJkMTJlZDEifUjhIzZotn56TEUm83mD7J4yOqI4Kxtl0pg9vsY4ArniSUAOsujFaI58lvmqi0bRVAfkvOnU53MHK5rEjV-JAqxuhQFvDYPfw3F2TLBn9enXkRhWBOnjcGrSqbZJLQOrTDn0OJeHoXA1CzhA_m3bWtJoZ-JvsTtTDnWZz9CdAB2y-zexTYSsGgzlWBi8g9Vw3Lp2YDS9uAQXPW9VfKv9p2rlKy7ru0kOI127Ic8LDn5ah4-gsV3Alh8Bhm1Dgi4aoX740UsNDn6YJNDGWvhIRGxkuFT4bFrhlXw4FZRQrVVqboTHdl7DE6iP1LddXTcFwZhy14cIt8V0-3FRA3GngE8.NjAyMWJkZWUtMGMyOS00NmRkLThjZTMtODEyOTkzZTUyMTBi',
            center: [-95.7369, 39.4654],
            zoom: 10
        });

        setMap(map);
        
        const nav = new maplibregl.NavigationControl();
        map.addControl(nav, 'top-right');

        map.on('load', () => {
            map.addSource('radar', {
                'type': 'raster',
                'tiles': [
                    'https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/ridge::TWX-N0B-0/{z}/{x}/{y}.png'
                ],
                'tileSize': 256
            });

            map.addLayer({
                'id': 'radar',
                'type': 'raster',
                'source': 'radar',
                'paint': {
                    'raster-opacity': 0.3
                }
            });
            map.addSource('counties', {
                'type': 'raster',
                'tiles': [
                    'https://mesonet.agron.iastate.edu/c/tile.py/1.0.0/uscounties/{z}/{x}/{y}.png'
                ],
                'tileSize': 256
            });

            map.addLayer({
                'id': 'county-layer',
                'type': 'raster',
                'source': 'counties',
                'paint': {
                    'raster-opacity': 0.3
                }
            });

            locations.forEach(async (location) => {
                try {
                    const el = document.createElement('div');
                    el.className = 'marker';

                    const data = await fetchCurrentConditions(location.site);

                    const popup = new maplibregl.Popup({ offset: 25 }).setHTML(`
                        <h3>${location.name}</h3>
                        <p>Temperature: ${data.temp}°F</p>
                        <p>Feels Like: ${data.feels}°F</p>
                        <p>Humidity: ${data.humidity}%</p>
                        <p>Wind: ${data.wind} mph ${data.windDir}</p>
                    `);

                    new maplibregl.Marker(el)
                        .setLngLat(location.coordinates)
                        .setPopup(popup)
                        .addTo(map);
                } catch (error) {
                    console.error(`Error fetching data for ${location.name}:`, error);
                }
            });
        });

        return () => map.remove();
    }, []);

    return (
        <Box className="map-frame">
            <Container header={<Header variant="h2">Weather Map</Header>} className="map-container">
                <div id="map" className="map-content"></div>
            </Container>
        </Box>
    );
};

export default WeatherMap;
