import React, { useState } from 'react';
import './App.css';
import CurrentConditions from './components/CurrentConditions';
import Forecast from './components/Forecast';
import YouTubeVideo from './components/YouTubeVideo';
import WeatherMap from './components/WeatherMap';
import { Select } from '@cloudscape-design/components';
import Graphs from './components/Graphs';
const cityOptions = [
    { label: 'Holton', value: 'KHLT' },
    { label: 'Denison', value: 'KDEN' }
];

function App() {
    const [selectedCity, setSelectedCity] = useState(cityOptions[0]);

    const handleCityChange = ({ detail }) => {
        setSelectedCity(detail.selectedOption);
    };

    return (
        <div className="App">
            <header className="App-header">
                <img src="https://s3.us-east-2.amazonaws.com/holtonksweather/images/NewBanner.png" alt="Banner" className="App-banner" />
            </header>
            <main>
                <Select
                    selectedOption={selectedCity}
                    onChange={handleCityChange}
                    options={cityOptions}
                    placeholder="Select a city"
                    className="city-select"
                />
                <div className="main-content">
                    <CurrentConditions site={selectedCity.value} city={selectedCity.label} />
                    <YouTubeVideo />
                </div>
                <Graphs city={selectedCity.value} />
                <Forecast />
                
                <WeatherMap />
            </main>
        </div>
    );
}

export default App;
